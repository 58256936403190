import { CRYPTO_SECRET_KEY } from 'config/app.config';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getUserSelectedService } from './StorageHelper';
import { userCurrentServiceSelector } from 'components/_trackivo/selectors/authSelectors';
import store from 'store';

export const encryptText = (message: any): string => {
  message = message.toString();
  const cipherText = CryptoJS.AES.encrypt(message, CRYPTO_SECRET_KEY);
  return encodeURIComponent(cipherText.toString());
};

export const decryptText = (message: any): string => {
  const bytes = CryptoJS.AES.decrypt(
    decodeURIComponent(message),
    CRYPTO_SECRET_KEY
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const encryptObject = (obj: object): string => {
  const convertedObj = JSON.stringify(obj);
  return CryptoJS.AES.encrypt(convertedObj, CRYPTO_SECRET_KEY).toString();
};

export const decryptObject = (obj: string): object => {
  return JSON.parse(
    CryptoJS.AES.decrypt(obj, CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8)
  );
};

export const getQueryParams = (url: string): object => {
  if (url.indexOf('?') == -1) {
    return {};
  }
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params: any = {};
  paramArr.map(param => {
    const [key, val] = param.split('=');
    const queryval = val.replace(/\+/g, '%20');
    params[key] = decodeURIComponent(queryval);
  });
  return params;
};
export const placeParamsToURL = (pathRegex: string, params?: any): string => {
  const serviceInfo = getUserSelectedService();
  // const serviceInfoSelector = userCurrentServiceSelector(store.getState());
  // const serviceInfo = serviceInfoSelector
  //   ? serviceInfoSelector
  //   : getUserSelectedService();
  //...................
  // const serviceInfo = userCurrentServiceSelector(store.getState());
  // const serviceSlug = 'ats';
  let serviceSlug = '';
  if (serviceInfo) {
    serviceSlug = serviceInfo.slug;
  }
  if (!serviceSlug) {
    pathRegex = pathRegex.replace('/:service_slug?/', '');
    if (!pathRegex) {
      pathRegex = '/';
    }
  }
  if (!params) {
    params = {
      service_slug: serviceSlug
    };
  } else {
    params = { ...params, ...{ service_slug: serviceSlug } };
  }
  let replacedString = pathRegex;

  if (params) {
    Object.keys(params).forEach((key: any) => {
      // const placeholderRegex = new RegExp(`:${key}(?![\\w-])`, 'g');
      const placeholderRegex = new RegExp(`:${key}\\??`, 'g');
      replacedString = replacedString.replace(placeholderRegex, params[key]);
    });
  }
  return replacedString;
};
export const placeParams = (pathRegex: string, params: any): string => {
  let replacedString = pathRegex;
  Object.keys(params).forEach((key: any) => {
    const placeholderRegex = new RegExp(`:${key}(?![\\w-])`, 'g');
    replacedString = replacedString.replace(placeholderRegex, params[key]);
  });
  return replacedString;

  // const segments = pathRegex.split('/');
  // return (
  //   segments
  //     .map(segment => {
  //       let offset = segment.indexOf(':?') + 1;
  //       let key;
  //       if (offset) {
  //         key = segment.slice(offset + 1);
  //         return params[key];
  //       }

  //       offset = segment.indexOf(':') + 1;
  //       if (!offset) return segment;

  //       key = segment.slice(offset);
  //       return params[key];
  //     })
  //     .join('/')
  //     // Remove trailing "/"
  //     .replace(/\/+$/, '')
  //     // Remove redundant "/"
  //     .replace(/\/{2,}/, '/')
  // );
};

export const generateRandomNumber = (): number => {
  // parseInt(Date.now() * Math.random());
  return Date.now() * Math.random();
};

export const capitalizeWord = (text: string): string => {
  const words = text.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (words[i][0]) {
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
  }
  return words.join(' ');
};

export const routeScrollToTop = (): void => {
  // called on route change
  window.scrollTo(0, 0);
};

export const numberFormatShort = (
  numberVal: number,
  precision: number = 0
): string => {
  let n_format = '';
  let suffix = 'T';
  if (numberVal < 999) {
    // 0 - 999
    n_format = numberVal.toFixed(precision);
    suffix = '';
  } else if (numberVal < 900000) {
    // 0.9k-850k
    n_format = (numberVal / 1000).toFixed(precision);
    suffix = 'K';
  } else if (numberVal < 900000000) {
    // 0.9m-850m
    n_format = (numberVal / 1000000).toFixed(precision);
    suffix = 'M';
  } else if (numberVal < 900000000000) {
    // 0.9b-850b
    n_format = (numberVal / 1000000000).toFixed(precision);
    suffix = 'B';
  } else {
    // 0.9t+
    n_format = (numberVal / 1000000000000).toFixed(precision);
    suffix = 'T';
  }
  return n_format + suffix;
};

export const checkActiveRoute = (
  activeRoute: string,
  routeList: string[],
  matchExactRoute: boolean = false
): boolean => {
  let isActive = false;
  if (!matchExactRoute) {
    const paths = activeRoute.split('/');
    isActive =
      paths.some(path => routeList.includes(path)) ||
      activeRoute.includes(routeList[0])
        ? true
        : false;
  } else {
    // className = routeList.every((e) => e.includes(activeRoute)) ? "active" : "";
    isActive = routeList.every(e => e == activeRoute) ? true : false;
  }
  return isActive;
};
export const getMaskedEmail = (email: string) => {
  // Number of characters to display
  const visibleCharacters = 3;

  // Mask the email address
  return (
    email.substring(0, visibleCharacters) +
    '*'.repeat(email.length - visibleCharacters) +
    ' '
  );
};
export const getTruncatedString = (stringVal: string, length = 20) => {
  if (stringVal.length > length)
    stringVal = stringVal.substring(0, length) + '...';
  return stringVal;
};
export const isNullOrEmptyObject = (obj: any): boolean => {
  return (
    obj === null || (typeof obj === 'object' && Object.keys(obj).length === 0)
  );
};
export const replaceNewlinesWithBR = (text: any) => {
  return text ? text.toString().replace(/\n/g, '<br />') : text;
};
export const allowNumberOnly = (event: any) => {
  if (
    event.key === 'ArrowLeft' ||
    event.key === 'ArrowRight' ||
    event.key === 'Backspace' ||
    event.key === 'Delete' ||
    event.key === 'Tab' ||
    event.key === 'Escape' ||
    event.key === 'Enter' ||
    (event.key >= '0' && event.key <= '9') ||
    (event.ctrlKey &&
      (event.key === 'c' ||
        event.key === 'v' ||
        event.key === 'x' ||
        event.key === 'a'))
  ) {
    // Allow the keypress
  } else {
    // Prevent the keypress
    event.preventDefault();
  }
};
export const allowDecimalNumberOnly = (event: any) => {
  const value = event.target.value;
  if (
    event.key === 'ArrowLeft' ||
    event.key === 'ArrowRight' ||
    event.key === 'Backspace' ||
    event.key === 'Delete' ||
    event.key === 'Tab' ||
    event.key === 'Escape' ||
    event.key === 'Enter' ||
    (event.key === '.' && value.indexOf('.') === -1) ||
    (event.key >= '0' && event.key <= '9') ||
    (event.ctrlKey &&
      (event.key === 'c' ||
        event.key === 'v' ||
        event.key === 'x' ||
        event.key === 'a'))
  ) {
    // Allow the keypress
    return true;
  } else {
    // Prevent the keypress
    event.preventDefault();
    return false;
  }
};
export const roundAmount = (value: any) => {
  return parseFloat(value).toFixed(2);
};
// Add change event listener to elements with a specific class
document.addEventListener('change', function (event) {
  const target = event.target as HTMLInputElement;
  if (target && target.classList.contains('allow_decimal_number_only')) {
    if (target.value) {
      target.value = roundAmount(target.value);
    }
  }
});
export const renderStr = (parameter: any) => {
  if (
    parameter == null ||
    parameter == '' ||
    parameter == '0000-00-00 00:00:00'
  ) {
    return '-';
  }
  return parameter;
};
export const formatSerialNo = (moduleType: string, num: number) => {
  let prefix: any = '';
  const serialNum = String(num).padStart(4, '0');
  if (moduleType == 'candidate') {
    prefix = 'CA';
  } else if (moduleType == 'job_opening') {
    prefix = 'JO';
  } else if (moduleType == 'client') {
    prefix = 'CL';
  } else if (moduleType == 'client_submission') {
    prefix = 'CS';
  } else if (moduleType == 'interview') {
    prefix = 'IN';
  } else if (moduleType == 'employee') {
    prefix = 'EM';
  } else if (moduleType == 'department') {
    prefix = 'DP';
  } else if (moduleType == 'branch') {
    prefix = 'BR';
  } else if (moduleType == 'leave') {
    prefix = 'LA';
  } else if (moduleType == 'account') {
    prefix = 'A';
  } else if (moduleType == 'lead') {
    prefix = 'L';
  } else if (moduleType == 'proposal') {
    prefix = 'P';
  }
  return prefix + serialNum;
};
