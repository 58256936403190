import {
  encryptObject,
  decryptObject,
  generateRandomNumber
} from './CommonHelper';
import store from 'store';
import { getCurrentDateTime } from './DateHelper';
import {
  getUserPermissionList,
  getUserAllServicePermissionList
} from './StorageHelper';
import {
  CANDIDATE_SLUG,
  INTERVIEW_SLUG,
  JOB_OPENING_SLUG,
  LEAD_SLUG
} from './DataHelper';
import { t } from 'i18next';

// export const hasUserAccessFunctionality = (functionalityName: string) => {
//   const hasPermission = checkUserPermission(functionalityName);
//   return hasPermission;
// };
export const permissionSlugs = {
  dashboard: 'dashboard',
  calendar: 'calendar',
  role_list: 'company.role.list',
  create_role: 'create.company.role',
  edit_role: 'edit.company.role',
  group_list: 'company.group.list',
  create_group: 'create.company.group',
  edit_group: 'edit.company.group',
  company_list: 'company.list',
  edit_company: 'edit.company',
  create_company: 'create.company',
  company_department_list: 'company.department.list',
  create_company_department: 'create.company.department',
  edit_company_department: 'edit.company.department',
  company_branch_list: 'company.branch.list',
  create_company_branch: 'create.company.branch',
  edit_company_branch: 'edit.company.branch',
  leave_type_list: 'company.leave.type.list',
  edit_leave_type: 'edit.company.leave.type',
  create_leave_type: 'create.company.leave.type',
  job_title_list: 'company.job.title.list',
  edit_job_title: 'edit.company.job.title',
  create_job_title: 'create.company.job.title',
  pay_type_list: 'company.pay.type.list',
  edit_pay_type: 'edit.company.pay.type',
  create_pay_type: 'create.company.pay.type',
  skill_list: 'company.skill.list',
  edit_skill: 'edit.company.skill',
  create_skill: 'create.company.skill',
  shift_list: 'company.shift.list',
  edit_shift: 'edit.company.shift',
  create_shift: 'create.company.shift',
  employee_type_list: 'company.employee.type.list',
  edit_employee_type: 'edit.company.employee.type',
  create_employee_type: 'create.company.employee.type',
  employee_list: 'company.employee.list',
  create_employee: 'create.company.employee',
  edit_employee: 'edit.company.employee',
  // employee_adjust_leave_balance: 'company.employee.adjust.leave.balance',
  // interview
  employee_adjust_leave_balance: 'other',
  interview_list: 'company.interview.list',
  create_interview: 'create.company.interview',
  edit_interview: 'edit.company.interview',
  submit_interview_evaluation: 'submit.company.interview.evaluation',
  interview_detail: 'detail.company.interview',
  // candidate
  candidate_list: 'company.candidate.list',
  create_candidate: 'create.company.candidate',
  edit_candidate: 'edit.company.candidate',
  manage_candiate_status: 'update.status.company.candidate',
  candidate_detail: 'detail.company.candidate', //candidate detail
  // candidate status
  candidate_status_list: 'company.candidate.status.list',
  edit_candidate_status_title: 'edit.company.candidate.status',
  create_candidate_status: 'create.company.candidate.status',
  // client
  client_list: 'company.client.list',
  create_client: 'create.company.client',
  edit_client: 'edit.company.client',
  // interview level
  edit_interview_level: 'edit.company.interview.level',
  create_interview_level: 'create.company.interview.level',
  interview_level_list: 'company.interview.level.list',
  // visa type
  visa_type_list: 'company.visa.type.list',
  edit_visa_type: 'edit.company.visa.type',
  create_visa_type: 'create.company.visa.type',
  // document type
  document_type_list: 'company.document.type.list',
  edit_document_type: 'edit.company.document.type',
  create_document_type: 'create.company.document.type',
  // source setting
  source_setting: 'company.source.setting',
  // job opening status
  job_opening_status_list: 'company.job.opening.status.list',
  edit_job_opening_status: 'edit.company.job.opening.status',
  create_job_opening_status: 'create.company.job.opening.status',
  // job opening
  job_opening_list: 'company.job.opening.list',
  edit_job_opening: 'edit.company.job.opening',
  share_access_job_opening: 'other',
  detail_job_opening: 'edit.company.job.opening',
  create_job_opening: 'create.company.job.opening',
  manage_job_opening_status: 'update.status.company.job.opening',
  job_opening_detail: 'detail.company.job.opening',
  // interview_status_list: 'company.interview.status.list',
  // edit_interview_status: 'edit.company.interview.status',
  // create_interview_status: 'create.company.interview.status',
  interview_status_list: 'company.interview.status.list',
  edit_interview_status: 'edit.company.interview.status',
  create_interview_status: 'create.company.interview.status',
  // leave module
  leave_status_list: 'company.leave.status.list',
  edit_leave_status: 'edit.company.leave.status',
  create_leave_status: 'create.company.leave.status',
  leave_tracker_list: 'company.leave.tracker.list',
  leave_application_list: 'company.leave.application.list',
  edit_leave: 'edit.company.leave',
  create_leave: 'create.company.leave',
  update_user_leave_status: 'other',
  // job attachment
  job_opening_attachment_category_list:
    'company.job.opening.attachment.category.list',
  edit_job_opening_attachment_category:
    'edit.company.job.opening.attachment.category',
  create_job_opening_attachment_category:
    'create.company.job.opening.attachment.category',
  submit_candidate_to_client: 'company.submit.candidate.to.client',
  unassociate_submited_candidate: 'company.unassociate.submit.candidate',
  submit_candidate_list: 'company.submit.candidate.list',
  other: 'other',
  // attendance
  attendance_list: 'company.attendance.list',
  attendance_report: 'company.attendance.report.list',
  attachment_list: 'company.job.opening.attachment.list',
  create_attachment: 'create.company.job.opening.attachment',
  edit_attachment: 'edit.company.job.opening.attachment',
  delete_attachment: 'delete.company.job.opening.attachment',
  // todo/event
  event_list: 'company.event.list',
  create_event: 'create.company.event',
  edit_event: 'edit.company.event',
  delete_event: 'delete.company.event',
  manage_event_status: 'other',
  // call log
  call_log_list: 'company.call.log.list',
  create_call_log: 'create.company.call.log',
  edit_call_log: 'edit.company.call.log',
  delete_call_log: 'delete.company.call.log',
  // task
  task_list: 'company.task.list',
  create_task: 'create.company.task',
  edit_task: 'edit.company.task',
  delete_task: 'delete.company.task',
  manage_task_status: 'other',
  // task label
  task_label_list: 'company.task.label.list',
  create_task_label: 'create.company.task.label',
  edit_task_label: 'edit.company.task.label',
  // task status
  task_status_list: 'company.task.status.list',
  create_task_status: 'create.company.task.status',
  edit_task_status: 'edit.company.task.status',

  // holiday
  user_holiday_list: 'company.holiday.list',
  manage_holiday_list: 'company.manage.holiday.list',
  create_holiday: 'create.company.manage.holiday',
  edit_holiday: 'edit.company.manage.holiday',
  delete_holiday: 'delete.company.manage.holiday',
  // active candidate
  active_candidate_detail: 'other',
  // reference
  reference_list: 'company.reference.list',
  create_reference: 'create.company.reference',
  edit_reference: 'edit.company.reference',
  // chat
  chat_list: 'company.chat.list',
  // ats workflow
  ats_workflow_diagram: 'ats.workflow.list',
  edit_ats_workflow_diagram: 'edit.ats.workflow',
  // crm modules
  service_category_list: 'company.service.category.list',
  create_service_category: 'create.company.service.category',
  edit_service_category: 'edit.company.service.category',
  company_service_list: 'company.service.category.list',
  create_company_service: 'create.company.service',
  edit_company_service: 'edit.company.service',
  // crm account
  account_list: 'company.account.list',
  create_account: 'create.company.account',
  edit_account: 'edit.company.account',
  share_access_account: 'other',
  // crm lead
  lead_stage_list: 'company.lead.stage.list',
  edit_lead_stage: 'edit.company.lead.stage',
  create_lead_stage: 'create.company.lead.stage',
  lead_list: 'company.lead.list',
  create_lead: 'create.company.lead',
  edit_lead: 'edit.company.lead',
  lead_detail: 'other',
  update_lead_status: 'update.status.company.lead',
  lead_development_list: 'company.development.list',
  // email signature
  signature_list: 'other',
  create_signature: 'other',
  edit_signature: 'other',
  delete_signature: 'other',
  // user email template
  email_template_list: 'other',
  create_email_template: 'other',
  edit_email_template: 'other',
  delete_email_template: 'other',
  // system email template
  system_email_template_list: 'other',
  create_system_email_template: 'other',
  edit_system_email_template: 'other',
  // send mail
  send_mail_list: 'other',
  send_mail_detail: 'other',
  send_mail: 'other',
  // proposal stage
  proposal_stage_list: 'other',
  edit_proposal_stage: 'other',
  create_proposal_stage: 'other',
  // proposal
  proposal_list: 'other',
  create_proposal: 'other',
  edit_proposal: 'other',
  proposal_detail: 'other',
  update_proposal_status: 'other'
};
// export const checkUserPermission = (functionalityName: string) => {
//   const permissionList = getUserPermissionList();
//   console.log(permissionList);

//   switch (functionalityName) {
//     case 'company_list':
//       return permissionList.includes('company.list');
//     case 'create_company':
//       return permissionList.includes('create.company');
//     case 'edit_company':
//       return permissionList.includes('edit.company');
//     case 'other':
//       return true;
//     default:
//       return false;
//   }
// };
export const hasUserAccessFunctionality = (functionalityName: any) => {
  const hasPermission = checkUserPermissionList(functionalityName);
  return hasPermission;
};
export const checkUserPermissionList = (functionalityList: any) => {
  const permissionList = getUserPermissionList();
  permissionList.push(permissionSlugs.dashboard);
  permissionList.push(permissionSlugs.calendar);
  permissionList.push(permissionSlugs.other);
  // console.log('perm....', permissionList, functionalityList);
  const hasPermission = permissionList.some((path: string) =>
    functionalityList.includes(path)
  );
  return hasPermission;
};
export const hasAddPermission = (moduleType: string) => {
  if (moduleType == 'skill') {
    return hasUserAccessFunctionality([permissionSlugs.create_skill]);
  } else if (moduleType == 'employee_type') {
    return hasUserAccessFunctionality([permissionSlugs.create_employee_type]);
  } else if (moduleType == 'job_title') {
    return hasUserAccessFunctionality([permissionSlugs.create_job_title]);
  } else if (moduleType == 'department') {
    return hasUserAccessFunctionality([
      permissionSlugs.create_company_department
    ]);
  } else if (moduleType == 'shift') {
    return hasUserAccessFunctionality([permissionSlugs.create_shift]);
  } else if (moduleType == 'leave_type') {
    return hasUserAccessFunctionality([permissionSlugs.create_leave_type]);
  } else if (moduleType == 'pay_type') {
    return hasUserAccessFunctionality([permissionSlugs.create_pay_type]);
  } else if (moduleType == 'client') {
    return hasUserAccessFunctionality([permissionSlugs.create_client]);
  } else if (moduleType == 'visa_type') {
    return hasUserAccessFunctionality([permissionSlugs.create_visa_type]);
  } else if (moduleType == 'reference') {
    return hasUserAccessFunctionality([permissionSlugs.create_reference]);
  }
  return false;
};
// check user's all permission from all allowed services... start................
export const checkUserAllPermission = (functionalityList: any) => {
  const permissionList = getUserAllServicePermissionList();
  permissionList.push(permissionSlugs.dashboard);
  permissionList.push(permissionSlugs.calendar);
  permissionList.push(permissionSlugs.other);
  const hasPermission = permissionList.some((path: string) =>
    functionalityList.includes(path)
  );
  return hasPermission;
};
export const hasUserAccessFunctionalityFromAllService = (
  functionalityName: any
) => {
  const hasPermission = checkUserAllPermission(functionalityName);
  return hasPermission;
};
// end........................

export const getPermissionwiseRelatedToType = () => {
  const relatedToList = [];
  if (
    hasUserAccessFunctionalityFromAllService([permissionSlugs.job_opening_list])
  ) {
    relatedToList.push({
      slug: JOB_OPENING_SLUG,
      name: t(JOB_OPENING_SLUG)
    });
  }
  if (
    hasUserAccessFunctionalityFromAllService([permissionSlugs.candidate_list])
  ) {
    relatedToList.push({
      slug: CANDIDATE_SLUG,
      name: t(CANDIDATE_SLUG)
    });
  }
  // if (hasUserAccessFunctionalityFromAllService([permissionSlugs.interview_list])) {
  //   relatedToList.push({
  //     slug: INTERVIEW_SLUG,
  //     name: t(INTERVIEW_SLUG)
  //   });
  // }
  if (hasUserAccessFunctionalityFromAllService([permissionSlugs.lead_list])) {
    relatedToList.push({
      slug: LEAD_SLUG,
      name: t(LEAD_SLUG)
    });
  }
  return relatedToList;
};
