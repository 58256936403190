// export const emailREGEX = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
export const emailREGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$/;
export const mobileNumberREGEX = /^\d{8,12}$/;
export const mobileNumberLength = 12;
export const postalCodeREGEX = /^.{6,7}$/;
export const websiteREGEX =
  /^(https?:\/\/)?([\w\d-]+\.)*[\w\d-]+\.[\w\d]{2,}(\/.*)?$/;
export const onlyCharacterREGEX = /^[a-zA-Z\s]*$/;
export const urlREGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}\/?$/;
export const linkedinURLREGEX = /^https:\/\/(www\.)?linkedin\.com\/.*$/;
export const facebookURLREGEX = /^https:\/\/(www\.)?facebook\.com\/.*$/;
export const twitterURLREGEX = /^https:\/\/(www\.)?twitter\.com\/.*$/;
export const regexList: any = {
  emailREGEX: emailREGEX,
  mobileNumberREGEX: mobileNumberREGEX,
  postalCodeREGEX: postalCodeREGEX
};
