import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import {
  Icon,
  UilChartPie,
  UilCube,
  UilDocumentLayoutRight,
  UilFilesLandscapesAlt,
  UilPuzzlePiece,
  UilSetting
} from '@iconscout/react-unicons';
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
// const { t } = useTranslation();
import t from 'i18n/config';
import { fieldMasterRoutes, mainRoutes } from 'config/routing';
import { permissionSlugs } from 'helpers/PermissionHelper';
import SearchCandidateSvg from 'components/_trackivo/common/Icons/SVGIcons/SearchCandidateSvg';
import CalendarSvg from 'components/_trackivo/common/Icons/SVGIcons/CalendarSvg';
import CandidateSvg from 'components/_trackivo/common/Icons/SVGIcons/CandidateSvg';
import InterviewSvg from 'components/_trackivo/common/Icons/SVGIcons/InterviewSvg';
import SubmitCandidateSvg from 'components/_trackivo/common/Icons/SVGIcons/SubmitCandidateSvg';
import JobOpeningSvg from 'components/_trackivo/common/Icons/SVGIcons/JobOpeningSvg';
import ClientSvg from 'components/_trackivo/common/Icons/SVGIcons/ClientSvg';
import DashboardSvg from 'components/_trackivo/common/Icons/SVGIcons/DashboardSvg';
import HolidaySvg from 'components/_trackivo/common/Icons/SVGIcons/HolidaySvg';
import EmployeeMenuSvg from 'components/_trackivo/common/Icons/SVGIcons/EmployeeMenuSvg';
import RoleSvg from 'components/_trackivo/common/Icons/SVGIcons/RoleSvg';
import GroupSvg from 'components/_trackivo/common/Icons/SVGIcons/GroupSvg';
import DepartmentSvg from 'components/_trackivo/common/Icons/SVGIcons/DepartmentSvg';
import BranchSvg from 'components/_trackivo/common/Icons/SVGIcons/BranchSvg';
import LeaveApplicationSvg from 'components/_trackivo/common/Icons/SVGIcons/LeaveApplicationSvg';
import AttendanceSvg from 'components/_trackivo/common/Icons/SVGIcons/AttendanceSvg';
import SettingSvg from 'components/_trackivo/common/Icons/SVGIcons/SettingSvg';
import ShiftSvg from 'components/_trackivo/common/Icons/SVGIcons/ShiftSvg';
import { placeParamsToURL } from 'helpers/CommonHelper';
import ServiceSvg from 'components/_trackivo/common/Icons/SVGIcons/ServiceSvg';
import DevelopmentSvg from 'components/_trackivo/common/Icons/SVGIcons/DevelopmentSvg';
import AccountSvg from 'components/_trackivo/common/Icons/SVGIcons/AccountSvg';
import LeadSvg from 'components/_trackivo/common/Icons/SVGIcons/LeadSvg';
import MeetingSvg from 'components/_trackivo/common/Icons/SVGIcons/MeetingSvg';
import TaskSvg from 'components/_trackivo/common/Icons/SVGIcons/TaskSvg';
import EmailTemplateSvg from 'components/_trackivo/common/Icons/SVGIcons/EmailTemplateSvg';
import ProposalSvg from 'components/_trackivo/common/Icons/SVGIcons/ProposalSvg';

export interface Route {
  name: string;
  label: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons' | 'svg';
  svgIcon?: any;
  activeSvgIcon?: any;
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  matchExactRoute?: boolean;
  new?: boolean;
  hasNew?: boolean;
  permission: any;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon | string;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  matchExactRoute?: boolean;
  name?: string;
  path?: string;
  pathName?: string;
  permission: any;
}

export const routes: RouteItems[] = [
  {
    label: t('general'),
    labelDisabled: true,
    icon: UilCube,
    permission: [permissionSlugs.dashboard],
    pages: [
      {
        name: 'e-commerce',
        label: t('dashboard'),
        // icon: 'pie-chart',
        path: mainRoutes.dashboard.path,
        pathName: 'default-dashboard',
        active: true,
        matchExactRoute: true,
        flat: true,
        permission: [permissionSlugs.dashboard],
        svgIcon: <DashboardSvg />,
        activeSvgIcon: <DashboardSvg />
      }
      // {
      //   name: 'email-template',
      //   label: t('email_template'),
      //   // icon: 'pie-chart',
      //   path: mainRoutes.emailTemplate.path,
      //   pathName: 'email_template',
      //   active: true,
      //   matchExactRoute: true,
      //   flat: true,
      //   permission: [permissionSlugs.email_template_list],
      //   svgIcon: <DashboardSvg />,
      //   activeSvgIcon: <DashboardSvg />
      // },

      // {
      //   name: 'calendar',
      //   label: t('calendar'),
      //   // icon: 'pie-chart',
      //   path: mainRoutes.calendar.path,
      //   pathName: 'default-calendar',
      //   active: true,
      //   flat: true,
      //   permission: [permissionSlugs.calendar],
      //   svgIcon: <CalendarSvg />,
      //   activeSvgIcon: <CalendarSvg />
      // },
      // {
      //   name: 'meeting',
      //   label: t('meeting'),
      //   // icon: 'pie-chart',
      //   path: mainRoutes.meeting.path,
      //   pathName: 'default-calendar',
      //   active: true,
      //   flat: true,
      //   permission: [permissionSlugs.event_list],
      //   svgIcon: <CalendarSvg />,
      //   activeSvgIcon: <CalendarSvg />
      // }
    ]
  },
  // // email_template
  // {
  //   label: t('emailtemplate'),
  //   icon: UilSetting,
  //   active: true,
  //   labelDisabled: true,
  //   permission: [
  //     permissionSlugs.email_template_list,
  //     permissionSlugs.system_email_template_list
  //   ],
  //   pages: [
  //     {
  //       label: t('email_template'),
  //       // icon: 'settings',
  //       name: t('email_template'),
  //       active: true,
  //       permission: [
  //         permissionSlugs.email_template_list,
  //         permissionSlugs.system_email_template_list
  //       ],
  //       svgIcon: <SettingSvg />,
  //       activeSvgIcon: <SettingSvg />,
  //       pages: [
  //         {
  //           name: 'automated-template',
  //           label: t('automated_template'),
  //           // icon: 'pie-chart',
  //           path: mainRoutes.systemEmailTemplate.path,
  //           pathName: 'automated_template',
  //           active: true,
  //           matchExactRoute: true,
  //           flat: true,
  //           permission: [permissionSlugs.system_email_template_list]
  //         },
  //         {
  //           name: 'user-template',
  //           label: t('personalized_template'),
  //           // icon: 'pie-chart',
  //           path: mainRoutes.emailTemplate.path,
  //           pathName: 'personalized_template',
  //           active: true,
  //           matchExactRoute: true,
  //           flat: true,
  //           permission: [permissionSlugs.email_template_list]
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    label: t('todo'),
    active: true,
    icon: UilCube,
    permission: [
      permissionSlugs.calendar,
      permissionSlugs.event_list,
      permissionSlugs.task_list
    ],
    pages: [
      {
        name: 'calendar',
        label: t('calendar'),
        // icon: 'pie-chart',
        path: mainRoutes.calendar.path,
        pathName: 'default-calendar',
        active: true,
        flat: true,
        permission: [permissionSlugs.calendar],
        svgIcon: <CalendarSvg />,
        activeSvgIcon: <CalendarSvg />
      },
      {
        name: 'meeting',
        label: t('meeting'),
        // icon: 'pie-chart',
        path: mainRoutes.meeting.path,
        pathName: 'default-calendar',
        active: true,
        flat: true,
        permission: [permissionSlugs.event_list],
        svgIcon: <MeetingSvg />,
        activeSvgIcon: <MeetingSvg />
      },
      {
        name: 'task',
        label: t('task'),
        // icon: 'pie-chart',
        path: mainRoutes.task.path,
        pathName: 'default-calendar',
        active: true,
        flat: true,
        permission: [permissionSlugs.task_list],
        svgIcon: <TaskSvg />,
        activeSvgIcon: <TaskSvg />
      }
    ]
  },
  // {
  //   label: t('role'),
  //   labelDisabled: true,
  //   icon: UilCube,
  //   permission: [permissionSlugs.role_list],
  //   pages: [
  //     {
  //       name: 'role',
  //       label: t('role'),
  //       icon: 'pie-chart',
  //       path: mainRoutes.role.path,
  //       pathName: 'role',
  //       active: true,
  //       flat: true,
  //       permission: [permissionSlugs.role_list]
  //     }
  //   ]
  // },
  {
    label: t('company'),
    labelDisabled: true,
    icon: UilCube,
    permission: [permissionSlugs.company_list],
    pages: [
      {
        name: 'company',
        label: t('company'),
        icon: 'pie-chart',
        path: mainRoutes.company.path,
        pathName: 'company',
        active: true,
        flat: true,
        permission: [permissionSlugs.company_list]
      }
    ]
  },
  // CRM
  {
    label: t('CRM'),
    icon: UilCube,
    active: true,
    permission: [
      permissionSlugs.company_service_list,
      permissionSlugs.account_list,
      permissionSlugs.lead_list,
      permissionSlugs.lead_development_list
    ],
    pages: [
      {
        name: 'service',
        label: t('service'),
        // icon: 'pie-chart',
        path: mainRoutes.companyService.path,
        pathName: 'service',
        active: true,
        flat: true,
        permission: [permissionSlugs.company_service_list],
        svgIcon: <ServiceSvg />,
        activeSvgIcon: <ServiceSvg />
      },
      {
        name: 'account',
        label: t('account'),
        // icon: 'pie-chart',
        path: mainRoutes.account.path,
        pathName: 'account',
        active: true,
        flat: true,
        permission: [permissionSlugs.account_list],
        svgIcon: <AccountSvg />,
        activeSvgIcon: <AccountSvg />
      },
      {
        name: 'lead',
        label: t('lead'),
        // icon: 'pie-chart',
        path: mainRoutes.lead.path,
        pathName: 'lead',
        active: true,
        flat: true,
        permission: [permissionSlugs.lead_list],
        svgIcon: <LeadSvg />,
        activeSvgIcon: <LeadSvg />
      },
      {
        name: 'lead-development',
        label: t('lead_development'),
        // icon: 'pie-chart',
        path: mainRoutes.leadDevelopment.path,
        pathName: 'lead',
        active: true,
        flat: true,
        permission: [permissionSlugs.lead_development_list],
        svgIcon: <DevelopmentSvg />,
        activeSvgIcon: <DevelopmentSvg />
      },
      {
        name: 'proposal',
        label: t('proposal'),
        // icon: 'pie-chart',
        path: mainRoutes.proposal.path,
        pathName: 'proposal',
        active: true,
        flat: true,
        permission: [permissionSlugs.proposal_list],
        svgIcon: <ProposalSvg />,
        activeSvgIcon: <ProposalSvg />
      }
    ]
  },
  // ATS
  {
    label: t('ATS'),
    icon: UilCube,
    active: true,
    permission: [
      permissionSlugs.candidate_list,
      permissionSlugs.client_list,
      permissionSlugs.interview_list,
      permissionSlugs.job_opening_list
    ],
    pages: [
      {
        name: 'search_candidate',
        label: t('search_candidate'),
        // icon: 'pie-chart',
        path: mainRoutes.searchCandidate.path,
        pathName: 'search_candidate',
        active: true,
        flat: true,
        permission: [permissionSlugs.candidate_list],
        svgIcon: <SearchCandidateSvg />,
        activeSvgIcon: <SearchCandidateSvg />
      },
      {
        name: 'candidate',
        label: t('candidate'),
        // icon: 'pie-chart',
        path: mainRoutes.candidate.path,
        pathName: 'candidate',
        active: true,
        flat: true,
        permission: [permissionSlugs.candidate_list],
        svgIcon: <CandidateSvg />,
        activeSvgIcon: <CandidateSvg />
      },

      // {
      //   name: 'active_candidate',
      //   label: t('active_candidate'),
      //   icon: 'pie-chart',
      //   path: mainRoutes.activeCandidate.path,
      //   pathName: 'active_candidate',
      //   active: true,
      //   flat: true,
      //   permission: [permissionSlugs.candidate_list]
      // },
      {
        name: 'client',
        label: t('client'),
        // icon: 'pie-chart',
        path: mainRoutes.client.path,
        pathName: 'client',
        active: true,
        flat: true,
        permission: [permissionSlugs.client_list],
        svgIcon: <ClientSvg />,
        activeSvgIcon: <ClientSvg />
      },
      {
        name: 'job_opening',
        label: t('job_opening'),
        // icon: 'pie-chart',
        path: mainRoutes.jobOpening.path,
        pathName: 'client',
        active: true,
        flat: true,
        permission: [permissionSlugs.job_opening_list],
        svgIcon: <JobOpeningSvg />,
        activeSvgIcon: <JobOpeningSvg />
      },
      {
        name: 'submittedCandidate',
        label: t('submitted_candidate'),
        // icon: 'pie-chart',
        path: mainRoutes.submittedCandidate.path,
        pathName: 'submittedCandidate',
        active: true,
        flat: true,
        permission: [permissionSlugs.submit_candidate_list],
        svgIcon: <SubmitCandidateSvg />,
        activeSvgIcon: <SubmitCandidateSvg />
      },
      {
        name: 'interview',
        label: t('interview'),
        // icon: 'pie-chart',
        /* svgIcon: (
          <Lottie
            animationData={calendarAnimationSvg}
            className="lottie"
            style={{ width: '18px' }}
          />
        ), */
        /*
        activeSvgIcon: (
          <Lottie
            animationData={flaticonCalendar}
            className="lottie"
            style={{ width: '18px' }}
          />
        ), */

        // svgIcon: <CalendarAnimationSvg width={20} />,
        // activeSvgIcon: <CalendarAnimationSvg width={20} />,
        path: mainRoutes.interview.path,
        pathName: 'interview',
        active: true,
        flat: true,
        permission: [permissionSlugs.interview_list],
        svgIcon: <InterviewSvg />,
        activeSvgIcon: <InterviewSvg />
      }
      // {
      //   name: 'chat',
      //   label: t('chat'),
      //   icon: 'pie-chart',
      //   path: mainRoutes.userChat.path,
      //   pathName: 'chat',
      //   active: true,
      //   flat: true,
      //   permission: [permissionSlugs.chat_list]
      // }
    ]
  },
  // STAFF
  {
    label: t('staff'),
    icon: UilCube,
    active: true,
    permission: [
      permissionSlugs.role_list,
      permissionSlugs.employee_list,
      permissionSlugs.group_list
    ],
    pages: [
      {
        name: 'role',
        label: t('role'),
        // icon: 'pie-chart',
        path: mainRoutes.role.path,
        pathName: 'role',
        active: true,
        flat: true,
        permission: [permissionSlugs.role_list],
        svgIcon: <RoleSvg />,
        activeSvgIcon: <RoleSvg />
      },
      {
        name: 'employee',
        label: t('employee'),
        // icon: 'pie-chart',
        path: mainRoutes.employee.path,
        pathName: 'employee',
        active: true,
        flat: true,
        permission: [permissionSlugs.employee_list],
        svgIcon: <EmployeeMenuSvg />,
        activeSvgIcon: <EmployeeMenuSvg />
      },
      {
        name: 'group',
        label: t('group'),
        // icon: 'pie-chart',
        path: mainRoutes.group.path,
        pathName: 'group',
        active: true,
        flat: true,
        permission: [permissionSlugs.group_list],
        svgIcon: <GroupSvg />,
        activeSvgIcon: <GroupSvg />
      }
    ]
  },
  // COMPANY
  {
    label: t('company'),
    icon: UilCube,
    active: true,
    permission: [
      permissionSlugs.company_department_list,
      permissionSlugs.company_branch_list,
      permissionSlugs.shift_list
    ],
    pages: [
      {
        name: 'department',
        label: t('department'),
        // icon: 'pie-chart',
        path: mainRoutes.department.path,
        pathName: 'department',
        active: true,
        flat: true,
        permission: [permissionSlugs.company_department_list],
        svgIcon: <DepartmentSvg />,
        activeSvgIcon: <DepartmentSvg />
      },
      {
        name: 'branch',
        label: t('branch'),
        // icon: 'pie-chart',
        path: mainRoutes.companyBranch.path,
        pathName: 'branch',
        active: true,
        flat: true,
        permission: [permissionSlugs.company_branch_list],
        svgIcon: <BranchSvg />,
        activeSvgIcon: <BranchSvg />
      },
      {
        name: 'shift',
        label: t('shift'),
        // icon: 'pie-chart',
        path: mainRoutes.shift.path,
        pathName: 'shift',
        active: true,
        flat: true,
        permission: [permissionSlugs.shift_list],
        svgIcon: <ShiftSvg />,
        activeSvgIcon: <ShiftSvg />
      }
    ]
  },
  // ATTENDANCE
  {
    label: t('employee'),
    icon: UilCube,
    active: true,
    permission: [
      permissionSlugs.leave_application_list,
      permissionSlugs.leave_tracker_list,
      permissionSlugs.attendance_list,
      permissionSlugs.attendance_report,
      permissionSlugs.manage_holiday_list
    ],
    pages: [
      {
        name: 'leave-application',
        label: t('leave_application'),
        // icon: 'pie-chart',
        path: mainRoutes.leaveApplication.path,
        pathName: 'leave_application',
        active: true,
        flat: true,
        permission: [permissionSlugs.leave_application_list],
        svgIcon: <LeaveApplicationSvg />,
        activeSvgIcon: <LeaveApplicationSvg />
      },
      {
        name: 'leave-tracker',
        label: t('leave_tracker'),
        // icon: 'pie-chart',
        path: mainRoutes.leaveTracker.path,
        pathName: 'leave_tracker',
        active: true,
        flat: true,
        permission: [permissionSlugs.leave_tracker_list],
        svgIcon: <LeaveApplicationSvg />,
        activeSvgIcon: <LeaveApplicationSvg />
      },
      {
        name: 'attendance',
        label: t('attendance'),
        // icon: 'pie-chart',
        path: mainRoutes.attendance.path,
        pathName: 'attendance',
        active: true,
        matchExactRoute: true,
        flat: true,
        permission: [permissionSlugs.attendance_list],
        svgIcon: <AttendanceSvg />,
        activeSvgIcon: <AttendanceSvg />
      },
      {
        name: 'attendance_report',
        label: t('attendance_report'),
        // icon: 'pie-chart',
        path: mainRoutes.attendanceReport.path,
        pathName: 'attendance',
        active: true,
        matchExactRoute: true,
        flat: true,
        permission: [permissionSlugs.attendance_report],
        svgIcon: <AttendanceSvg />,
        activeSvgIcon: <AttendanceSvg />
      },
      {
        name: 'manage_holiday',
        label: t('manage_holiday'),
        // icon: 'pie-chart',
        path: mainRoutes.manageHoliday.path,
        pathName: 'manageHoliday',
        active: true,
        flat: true,
        permission: [permissionSlugs.manage_holiday_list],
        svgIcon: <HolidaySvg />,
        activeSvgIcon: <HolidaySvg />
      },
      {
        name: 'user_holiday',
        label: t('holiday'),
        // icon: 'pie-chart',
        path: mainRoutes.userHolidayList.path,
        pathName: 'userHolidayList',
        active: true,
        flat: true,
        permission: [permissionSlugs.user_holiday_list],
        svgIcon: <HolidaySvg />,
        activeSvgIcon: <HolidaySvg />
      }
    ]
  },
  // general settings
  {
    label: t('general'),
    icon: UilSetting,
    active: true,
    permission: [
      permissionSlugs.skill_list,
      permissionSlugs.task_label_list,
      permissionSlugs.task_status_list,
      permissionSlugs.leave_type_list,
      permissionSlugs.job_title_list,
      permissionSlugs.pay_type_list,
      permissionSlugs.shift_list,
      permissionSlugs.employee_type_list,
      permissionSlugs.interview_level_list,
      permissionSlugs.candidate_status_list,
      permissionSlugs.source_setting,
      permissionSlugs.visa_type_list,
      permissionSlugs.service_category_list,
      permissionSlugs.lead_stage_list,
      permissionSlugs.proposal_stage_list,
      permissionSlugs.document_type_list,
      permissionSlugs.job_opening_status_list,
      permissionSlugs.job_opening_attachment_category_list,
      permissionSlugs.ats_workflow_diagram
    ],
    pages: [
      // {
      //   label: t('settings'),
      //   name: 'notifications',
      //   icon: 'bell',
      //   path: '/pages/notifications',
      //   pathName: 'notifications-page',
      //   permission: [permissionSlugs.other],
      //   active: true
      // },
      {
        label: t('email_template'),
        // icon: 'settings',
        name: t('email_template'),
        active: true,
        permission: [
          permissionSlugs.email_template_list,
          permissionSlugs.system_email_template_list
        ],
        svgIcon: <EmailTemplateSvg />,
        activeSvgIcon: <EmailTemplateSvg />,
        pages: [
          {
            name: 'automated-template',
            label: t('automated_template'),
            // icon: 'pie-chart',
            path: mainRoutes.systemEmailTemplate.path,
            pathName: 'automated_template',
            active: true,
            matchExactRoute: true,
            flat: true,
            permission: [permissionSlugs.system_email_template_list]
          },
          {
            name: 'user-template',
            label: t('personalized_template'),
            // icon: 'pie-chart',
            path: mainRoutes.emailTemplate.path,
            pathName: 'personalized_template',
            active: true,
            matchExactRoute: true,
            flat: true,
            permission: [permissionSlugs.email_template_list]
          }
        ]
      },
      {
        label: t('settings'),
        // icon: 'settings',
        name: 'settings',
        active: true,
        permission: [
          permissionSlugs.skill_list,
          permissionSlugs.task_label_list,
          permissionSlugs.task_status_list,
          permissionSlugs.leave_type_list,
          permissionSlugs.job_title_list,
          permissionSlugs.pay_type_list,
          permissionSlugs.employee_type_list,
          permissionSlugs.interview_level_list,
          permissionSlugs.candidate_status_list,
          permissionSlugs.source_setting,
          permissionSlugs.visa_type_list,
          permissionSlugs.service_category_list,
          permissionSlugs.lead_stage_list,
          permissionSlugs.proposal_stage_list,
          permissionSlugs.job_opening_status_list,
          permissionSlugs.job_opening_attachment_category_list,
          permissionSlugs.document_type_list,
          permissionSlugs.interview_status_list,
          permissionSlugs.leave_status_list,
          permissionSlugs.ats_workflow_diagram
        ],
        svgIcon: <SettingSvg />,
        activeSvgIcon: <SettingSvg />,
        pages: [
          {
            name: 'source_setting',
            label: t('source_setting'),
            // icon: 'pie-chart',
            path: mainRoutes.sourceSetting.path,
            pathName: 'source_setting',
            active: true,
            // flat: true,
            permission: [permissionSlugs.source_setting]
          },
          {
            name: 'calendar_label',
            label: t('calendar_label'),
            // icon: 'pie-chart',
            path: mainRoutes.calendarTaskLable.path,
            pathName: 'calendar_label',
            active: true,
            // flat: true,
            permission: [permissionSlugs.task_label_list]
          },
          {
            name: 'calendar_status',
            label: t('calendar_status'),
            // icon: 'pie-chart',
            path: mainRoutes.calendarTaskStatus.path,
            pathName: 'calendar_status',
            active: true,
            // flat: true,
            permission: [permissionSlugs.task_status_list]
          },
          // {
          //   name: 'reference',
          //   label: t('reference'),
          //   // icon: 'pie-chart',
          //   path: mainRoutes.reference.path,
          //   pathName: 'reference',
          //   active: true,
          //   // flat: true,
          //   permission: [permissionSlugs.reference_list]
          // },
          {
            name: 'service_category',
            label: t('service_category'),
            // icon: 'pie-chart',
            path: mainRoutes.serviceCategory.path,
            pathName: 'service_category',
            active: true,
            // flat: true,
            permission: [permissionSlugs.service_category_list]
          },
          {
            name: 'lead_stage',
            label: t('lead_stage'),
            // icon: 'pie-chart',
            path: mainRoutes.leadStage.path,
            pathName: 'lead_stage',
            active: true,
            // flat: true,
            permission: [permissionSlugs.lead_stage_list]
          },
          {
            name: 'proposal_stage',
            label: t('proposal_stage'),
            // icon: 'pie-chart',
            path: mainRoutes.proposalStage.path,
            pathName: 'proposal_stage',
            active: true,
            // flat: true,
            permission: [permissionSlugs.proposal_stage_list]
          },
          {
            name: 'visa_type',
            label: t('visa_type'),
            // icon: 'pie-chart',
            path: mainRoutes.visaType.path,
            pathName: 'visa_type',
            active: true,
            // flat: true,
            permission: [permissionSlugs.visa_type_list]
          },
          {
            name: 'document_type',
            label: t('document_type'),
            // icon: 'pie-chart',
            path: mainRoutes.documentType.path,
            pathName: 'document_type',
            active: true,
            // flat: true,
            permission: [permissionSlugs.document_type_list]
          },
          {
            name: 'skill',
            label: t('skill'),
            // icon: 'pie-chart',
            path: mainRoutes.skill.path,
            pathName: 'skill',
            active: true,
            // flat: true,
            permission: [permissionSlugs.skill_list]
          },
          {
            name: 'leave_type',
            label: t('leave_type'),
            // icon: 'pie-chart',
            path: mainRoutes.leaveType.path,
            pathName: 'leave-type',
            active: true,
            // flat: true,
            permission: [permissionSlugs.leave_type_list]
          },
          {
            name: 'leave_status',
            label: t('leave_status'),
            // icon: 'pie-chart',
            path: mainRoutes.leaveStatus.path,
            pathName: 'leave_status',
            active: true,
            // flat: true,
            permission: [permissionSlugs.leave_status_list]
          },
          {
            name: 'job_title',
            label: t('job_title'),
            // icon: 'pie-chart',
            path: mainRoutes.jobtitle.path,
            pathName: 'job-title',
            active: true,
            // flat: true,
            permission: [permissionSlugs.job_title_list]
          },
          {
            name: 'job_opening_status',
            label: t('job_opening_status'),
            // icon: 'pie-chart',
            path: mainRoutes.jobOpeningStatus.path,
            pathName: 'job_opening_status',
            active: true,
            // flat: true,
            permission: [permissionSlugs.job_opening_status_list]
          },
          {
            name: 'interview_status',
            label: t('interview_status'),
            // icon: 'pie-chart',
            path: mainRoutes.interviewStatus.path,
            pathName: 'interview_status',
            active: true,
            // flat: true,
            permission: [permissionSlugs.interview_status_list]
          },
          {
            name: 'job_opening_attachment_category_list',
            label: t('attachment_category'),
            // icon: 'pie-chart',
            path: mainRoutes.jobOpeningAttachmentCategory.path,
            pathName: 'job_opening_attachment_category_list',
            active: true,
            // flat: true,
            permission: [permissionSlugs.job_opening_attachment_category_list]
          },
          {
            name: 'pay_type',
            label: t('pay_type'),
            // icon: 'pie-chart',
            path: mainRoutes.payType.path,
            pathName: 'pay-type',
            active: true,
            // flat: true,
            permission: [permissionSlugs.pay_type_list]
          },
          {
            name: 'employee_type',
            label: t('employee_type'),
            // icon: 'pie-chart',
            path: mainRoutes.employeeType.path,
            pathName: 'employee-type',
            active: true,
            // flat: true,
            permission: [permissionSlugs.employee_type_list]
          },
          {
            name: 'interview_level',
            label: t('interview_level'),
            // icon: 'pie-chart',
            path: mainRoutes.interViewLevel.path,
            pathName: 'interview-level',
            active: true,
            // flat: true,
            permission: [permissionSlugs.interview_level_list]
          },
          {
            name: 'candidate_status',
            label: t('candidate_stage'),
            // icon: 'pie-chart',
            path: mainRoutes.candidateStatus.path,
            pathName: 'candidate-status',
            active: true,
            // flat: true,
            permission: [permissionSlugs.candidate_status_list]
          },
          {
            name: 'ats_workflow',
            label: t('ats_workflow'),
            // icon: 'pie-chart',
            path: mainRoutes.atsWorkflow.path,
            pathName: 'ats-workflow',
            active: true,
            // flat: true,
            permission: [permissionSlugs.ats_workflow_diagram]
          }
        ]
      }
    ]
  }
  // {
  //   label: t('settings'),
  //   name: 'multi-level',
  //   icon: UilSetting,
  //   active: true,
  //   permission: [permissionSlugs.other],
  //   pages: [
  //     {
  //       label: t('field_master'),
  //       icon: 'layers',
  //       name: 'field-master',
  //       active: true,
  //       permission: [permissionSlugs.other],
  //       pages: [
  //         {
  //           label: t('manage_field'),
  //           name: 'manage_field',
  //           path:
  //             mainRoutes.fieldMaster.path +
  //             '/' +
  //             fieldMasterRoutes.manageFields.path,
  //           active: true,
  //           permission: [permissionSlugs.other]
  //         },
  //         {
  //           label: t('manage_order'),
  //           name: 'manage_order',
  //           path:
  //             mainRoutes.fieldMaster.path +
  //             '/' +
  //             fieldMasterRoutes.manageOrder.path,
  //           active: true,
  //           permission: [permissionSlugs.other]
  //         }
  //       ]
  //     }
  //   ]
  // }
];
