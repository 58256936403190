import React from 'react';

const ProposalSvg = ({
  height = 21,
  width = 21,
  viewBox = '0 0 512 512'
}: {
  height?: any;
  width?: any;
  viewBox?: any;
}) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path
        fill="currentColor"
        d="m146.113 17.254a17.276 17.276 0 0 1 17.238-17.254h247.563a17.275 17.275 0 0 1 17.238 17.254v330.517l-62.661 47.092a23.852 23.852 0 0 1 -16.8 5.065l-113.425-6.49a1.8 1.8 0 0 1 -.942-.554 2.3 2.3 0 0 1 -.53-1.662c.1-1.187 1.915-1.741 3.19-1.741l98.933.633c35.259 2.77 40.895-17.571 41.781-23.9 2.572-18.283-13.851-38.7-35.473-45.668h42.121a13.3 13.3 0 1 0 0-26.593h-194.425a13.321 13.321 0 0 0 -9.11 3.562 50.119 50.119 0 0 0 -17.824 3.086l-16.874 6.015zm25.794 308.356-82.566 29.442 19.391 113.417 25.881-9.418a13.977 13.977 0 0 1 5.715-.713l217.281 19.233a24.322 24.322 0 0 0 19.976-7.044l133.544-129.246a9.62 9.62 0 0 0 -14.088-12.031l-115.578 86.9a50.908 50.908 0 0 1 -34.3 10.368l-113.424-6.569a27.914 27.914 0 0 1 -19.486-9.656 29.034 29.034 0 0 1 -6.957-21.29c1.25-14.88 13.977-26.118 29.64-26.118h.214l99.423.633a4.487 4.487 0 0 1 1.029.079c8.509.712 12.616-.633 13.8-1.425 0-3.561-7.377-14.4-19.842-17.016l-147.56-20.734a24.18 24.18 0 0 0 -12.094 1.188zm-113.655 5.382 29.237 171.036-58.26 9.972-29.229-171.036zm118.372-131.226a13.274 13.274 0 0 0 13.3 13.218h194.42a13.257 13.257 0 1 0 0-26.514h-194.423a13.29 13.29 0 0 0 -13.3 13.3zm124.26-72.656a6.5 6.5 0 0 1 -3.284 6.569 20.315 20.315 0 0 1 -10.464 2.849 20.655 20.655 0 0 1 -18.417-11.318 13.281 13.281 0 1 0 -23.681 12.03 47.063 47.063 0 0 0 28.8 23.982v7.44a13.3 13.3 0 0 0 26.593 0v-7.44a48.877 48.877 0 0 0 10.756-4.67 33.446 33.446 0 0 0 -6.094-60.31l-27.179-9.418a6.858 6.858 0 0 1 -1.251-12.347 20.312 20.312 0 0 1 10.471-2.849 20.685 20.685 0 0 1 18.418 11.318 13.277 13.277 0 1 0 23.672-12.031 47.043 47.043 0 0 0 -28.793-23.981v-7.439a13.3 13.3 0 1 0 -26.593 0v7.439a45.58 45.58 0 0 0 -10.764 4.749 33.385 33.385 0 0 0 6.1 60.231l27.163 9.418a6.427 6.427 0 0 1 4.543 5.778zm-124.26 129.879a13.29 13.29 0 0 0 13.3 13.3h194.42a13.3 13.3 0 1 0 0-26.593h-194.423a13.341 13.341 0 0 0 -13.297 13.293z"
        fillRule="evenodd"
      />
    </svg>
  );
};
export default ProposalSvg;
