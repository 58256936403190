import React, { lazy, ReactElement } from 'react';
import WizardExample from 'pages/modules/forms/WizardExample';
import DatePickerExample from 'pages/modules/forms/advance/DatePickerExample';
import { placeParamsToURL } from 'helpers/CommonHelper';
// Signin pages
const SignInPage = lazy(() => import('pages/Authentication/SignIn'));
const ForgotPasswordPage = lazy(
  () => import('pages/Authentication/ForgotPassword')
);
const ResetPasswordPage = lazy(
  () => import('pages/Authentication/ResetPassword')
);
const CreatePasswordPage = lazy(
  () => import('pages/Authentication/CreatePassword')
);
const TwoFAPage = lazy(() => import('pages/Authentication/TwoFA'));
// const LockScreenPage = lazy(() => import('pages/Authentication/LockScreen'));

// manage fields
const FieldMasterPage = lazy(() => import('pages/FieldMaster'));
const ManageFieldPage = lazy(() => import('pages/FieldMaster/ManageField'));
const ManageFieldOrderPage = lazy(
  () => import('pages/FieldMaster/ManageOrder')
);
// company
const CompanyListPage = lazy(() => import('pages/Company'));
const AddCompanyPage = lazy(() => import('pages/Company/AddCompany'));
const EditCompanyPage = lazy(() => import('pages/Company/EditCompany'));

// employee
const EmployeeListPage = lazy(() => import('pages/Employee'));
const AddEmployeePage = lazy(() => import('pages/Employee/AddEmployee'));
const EditEmployeePage = lazy(() => import('pages/Employee/EditEmployee'));
//client
const ClientListPage = lazy(() => import('pages/Client'));
const AddClientPage = lazy(() => import('pages/Client/AddClient'));
const EditClientPage = lazy(() => import('pages/Client/EditClient'));
const SubmittedCandidateListPage = lazy(
  () => import('pages/Client/SubmittedCandidate')
);
// chat functionality
const ChatPage = lazy(() => import('pages/UserChat'));
// department
const DepartmentListPage = lazy(() => import('pages/Department'));
const AddDepartmentPage = lazy(() => import('pages/Department/AddDepartment'));
const EditDepartmentPage = lazy(
  () => import('pages/Department/EditDepartment')
);
// candidate
const CandidateListPage = lazy(() => import('pages/Candidate'));
const CandidateDetailPage = lazy(
  () => import('pages/Candidate/CandidateDetail')
);
const ActiveCandidateListPage = lazy(() => import('pages/ActiveCandidate'));
const ActiveCandidateDetailPage = lazy(
  () => import('pages/ActiveCandidate/ActiveCandidateDetail')
);
const SearchCandidatePage = lazy(
  () => import('pages/Candidate/SearchCandidate')
);
const SourceBoosterCandidateDetailPage = lazy(
  () => import('pages/Candidate/SourceBoosterCandidateDetail')
);
const AddCandidatePage = lazy(() => import('pages/Candidate/AddCandidate'));
const EditCandidatePage = lazy(() => import('pages/Candidate/EditCandidate'));
// interview
const InterviewListPage = lazy(() => import('pages/Interview'));
const AddInterviewPage = lazy(() => import('pages/Interview/AddInterview'));
const EditInterviewPage = lazy(() => import('pages/Interview/EditInterview'));
const InterviewDetailPage = lazy(
  () => import('pages/Interview/InterviewDetail')
);
// company branch
const CompanyBranchListPage = lazy(() => import('pages/CompanyBranch'));
const AddCompanyBranchPage = lazy(
  () => import('pages/CompanyBranch/AddCompanyBranch')
);
const EditCompanyBranchPage = lazy(
  () => import('pages/CompanyBranch/EditCompanyBranch')
);
//leave type
const LeaveTypeListPage = lazy(() => import('pages/LeaveType'));
//job opening status
const JobOpeningStatusPage = lazy(() => import('pages/JobOpeningStatus'));
//job opening attachment category
const JobOpeningAttachmentCategoryListPage = lazy(
  () => import('pages/JobOpeningAttachmentCategory')
);
//leave status
const LeaveStatusListPage = lazy(() => import('pages/LeaveStatus'));
//leave tracker
const LeaveTrackerListPage = lazy(() => import('pages/LeaveTracker'));
//leave application
const LeaveApplicationListPage = lazy(() => import('pages/LeaveApplication'));
//Attendance
const AttendanceListPage = lazy(() => import('pages/Attendance'));
const AttendanceReportListPage = lazy(
  () => import('pages/Attendance/AttendanceReport')
);
// holiday
const ManageHolidayListPage = lazy(() => import('pages/ManageHoliday'));
const UserHolidayListPage = lazy(() => import('pages/UserHoliday'));
//interview status
const InterviewStatusListPage = lazy(() => import('pages/InterviewStatus'));
//job opening
const JobOpeningPage = lazy(() => import('pages/JobOpening'));
const AddJobOpeningPage = lazy(() => import('pages/JobOpening/AddJobOpening'));
const EditJobOpeningPage = lazy(
  () => import('pages/JobOpening/EditJobOpening')
);
const ShareAccessJobOpeningPage = lazy(
  () => import('pages/JobOpening/ShareAccessJobOpening')
);
const JobOpeningDetailPage = lazy(
  () => import('pages/JobOpening/JobOpeningDetail')
);
//job title
const JobTitlePage = lazy(() => import('pages/JobTitle'));
//source settings
const SourceSettingPage = lazy(() => import('pages/SourceBoosterSetting'));
//pay type
const PayTypeListPage = lazy(() => import('pages/PayType'));
//skill
const SkillListPage = lazy(() => import('pages/Skill'));
//shift
const ShiftListPage = lazy(() => import('pages/Shift'));
//employee type
const EmployeeTypePage = lazy(() => import('pages/EmployeeType'));
//candidate status
const CandidateStatusPage = lazy(() => import('pages/CandidateStatus'));
//Lead Stage
const LeadStagePage = lazy(() => import('pages/LeadStage'));
//Proposal Stage
const ProposalStagePage = lazy(() => import('pages/ProposalStage'));
//Account
const AccountPage = lazy(() => import('pages/Account'));
const AddAccountPage = lazy(() => import('pages/Account/AddAccount'));
const EditAccountPage = lazy(() => import('pages/Account/EditAccount'));
const ShareAccessAccountPage = lazy(
  () => import('pages/Account/ShareAccessAccount')
);
//Lead
const LeadPage = lazy(() => import('pages/Lead'));
const AddLeadPage = lazy(() => import('pages/Lead/AddLead'));
const EditLeadPage = lazy(() => import('pages/Lead/EditLead'));
const LeadDetailPage = lazy(() => import('pages/Lead/LeadDetail'));
// Lead Development
const LeadDevelopmentPage = lazy(() => import('pages/LeadDevelopment'));
//Proposal
const ProposalPage = lazy(() => import('pages/Proposal'));
const AddProposalPage = lazy(() => import('pages/Proposal/AddProposal'));
const EditProposalPage = lazy(() => import('pages/Proposal/EditProposal'));
//interview level
const InterViewLevelPage = lazy(() => import('pages/InterviewLevel'));
//visa Type
const VisaTypePage = lazy(() => import('pages/VisaType'));
//service Category
const ServiceCategoryPage = lazy(() => import('pages/ServiceCategory'));
//service
const ServicePage = lazy(() => import('pages/CompanyService'));
// reference
const ReferencePage = lazy(() => import('pages/Reference'));
//document type
const DocumentTypePage = lazy(() => import('pages/DocumentType'));
// role
const RoleListPage = lazy(() => import('pages/Role'));
const AddRolePage = lazy(() => import('pages/Role/AddRole'));
const EditRolePage = lazy(() => import('pages/Role/EditRole'));
const RoleselectionPage = lazy(() => import('pages/Role/RoleSelection'));
// Accept/Reject Access page
const AcceptRejectAccessInvitationPage = lazy(
  () => import('pages/Role/AcceptRejectAccessInvitation')
);

// group
const GroupListPage = lazy(() => import('pages/Group'));
const AddGroupPage = lazy(() => import('pages/Group/AddGroup'));
const EditGroupPage = lazy(() => import('pages/Group/EditGroup'));
// profile
const UserProfilePage = lazy(() => import('pages/Profile'));
// Settings
const UserSettingPage = lazy(() => import('pages/UserSetting'));
const UserSettingUIPage = lazy(() => import('pages/UserSetting/UserSettingUI'));
const UserSettingLinkAccountTypeCallbackPage = lazy(
  () => import('pages/UserSetting/LinkAccountTypeCallback')
);
const EmailSettingLinkAccountTypeCallbackPage = lazy(
  () => import('pages/UserSetting/LinkEmailAccountTypeCallback')
);
//User Email Template
const EmailTemplateListPage = lazy(() => import('pages/EmailTemplate'));
const AddEmailTemplatePage = lazy(
  () => import('pages/EmailTemplate/AddEmailTemplate')
);
const EditEmailTemplatePage = lazy(
  () => import('pages/EmailTemplate/EditEmailTemplate')
);
//System Email Template
const SystemTemplateListPage = lazy(() => import('pages/SystemEmailTemplate'));
const AddSystemEmailTemplatePage = lazy(
  () => import('pages/SystemEmailTemplate/AddSystemEmailTemplate')
);
const EditSystemEmailTemplatePage = lazy(
  () => import('pages/SystemEmailTemplate/EditSystemEmailTemplate')
);
// Dashboard
const DashboardPage = lazy(() => import('pages/dashboard'));
// work flow
const AtsWorkflowPage = lazy(() => import('pages/AtsWorkFlow'));
// google drive storage
const GoogleDriveStoragePage = lazy(() => import('pages/GoogleDriveStorage'));
// calendar
const CalendarPage = lazy(() => import('pages/Calendar'));
const MeetingPage = lazy(() => import('pages/Calendar/Meeting'));
const TaskPage = lazy(() => import('pages/Calendar/Task'));
//candidate status
const CalendarTaskStatusPage = lazy(
  () => import('pages/CalendarLabelStatus/CalendarStatus')
);
const CalendarTaskLabelPage = lazy(
  () => import('pages/CalendarLabelStatus/CalendarLabel')
);
//..
const DarkModeDemoPage = lazy(() => import('pages/pages/layouts/DarkModeDemo'));
const DarkModeCustomizePage = lazy(
  () => import('pages/documentation/customization/DarkMode')
);
const StarterPage = lazy(() => import('pages/pages/Starter'));
interface Route {
  path: string;
  element: ReactElement;
  routes?: { [key: string]: Route };
}
export const fieldMasterRoutes = {
  manageFields: { path: 'manage-fields', element: <ManageFieldPage /> },
  manageOrder: { path: 'manage-order', element: <ManageFieldOrderPage /> }
};
export const mainRoutes: { [key: string]: Route } = {
  // google drive storage
  googleDriveStorage: {
    path: 'file-storage',
    element: <GoogleDriveStoragePage />
  },
  // Dashboard
  dashboard: { path: '/:service_slug?/', element: <DashboardPage /> },
  atsWorkflow: {
    path: '/:service_slug?/ats-work-flow',
    element: <AtsWorkflowPage />
  },
  calendar: {
    path: '/:service_slug?/calendar',
    element: <CalendarPage />
  },
  meeting: {
    path: '/:service_slug?/meeting',
    element: <MeetingPage />
  },
  task: {
    path: '/:service_slug?/task',
    element: <TaskPage />
  },
  calendarTaskLable: {
    path: '/:service_slug?/calendar-label',
    element: <CalendarTaskLabelPage />
  },
  calendarTaskStatus: {
    path: '/:service_slug?/calendar-status',
    element: <CalendarTaskStatusPage />
  },
  fieldMaster: {
    path: '/:service_slug?/field-master',
    element: <FieldMasterPage />,
    routes: fieldMasterRoutes
  },
  // role
  role: {
    path: '/:service_slug?/role',
    element: <RoleListPage />
  },
  addRole: {
    path: '/:service_slug?/role/add',
    element: <AddRolePage />
  },
  editRole: {
    path: '/:service_slug?/role/edit/:id',
    element: <EditRolePage />
  },
  // group
  group: {
    path: '/:service_slug?/group',
    element: <GroupListPage />
  },
  addGroup: {
    path: '/:service_slug?/group/add',
    element: <AddGroupPage />
  },
  editGroup: {
    path: '/:service_slug?/group/edit/:id',
    element: <EditGroupPage />
  },
  // company
  company: {
    path: '/:service_slug?/company',
    element: <CompanyListPage />
  },
  addCompany: {
    path: '/:service_slug?/company/add',
    element: <AddCompanyPage />
  },
  editCompany: {
    path: '/:service_slug?/company/edit/:id',
    element: <EditCompanyPage />
  },
  candidate: {
    path: '/:service_slug?/candidate',
    element: <CandidateListPage />
  },
  candidateDetail: {
    path: '/:service_slug?/candidate/detail/:id',
    element: <CandidateDetailPage />
  },
  activeCandidate: {
    path: '/:service_slug?/active-candidate',
    element: <ActiveCandidateListPage />
  },
  activeCandidateDetail: {
    path: '/:service_slug?/active-candidate/detail/:id',
    element: <ActiveCandidateDetailPage />
  },
  addCandidate: {
    path: '/:service_slug?/candidate/add',
    element: <AddCandidatePage />
  },
  editCandidate: {
    path: '/:service_slug?/candidate/edit/:id',
    element: <EditCandidatePage />
  },
  searchCandidate: {
    path: '/:service_slug?/search-candidate',
    element: <SearchCandidatePage />
  },
  sourceBoosterCandidateDetail: {
    path: '/:service_slug?/search-candidate/detail/:type/:id',
    element: <SourceBoosterCandidateDetailPage />
  },
  // interview.......
  interview: {
    path: '/:service_slug?/interview',
    element: <InterviewListPage />
  },
  addInterview: {
    path: '/:service_slug?/interview/add',
    element: <AddInterviewPage />
  },
  editInterview: {
    path: '/:service_slug?/interview/edit/:id',
    element: <EditInterviewPage />
  },
  interviewDetail: {
    path: '/:service_slug?/interview/detail/:id',
    element: <InterviewDetailPage />
  },
  // job opening.......
  jobOpening: {
    path: '/:service_slug?/job-opening',
    element: <JobOpeningPage />
  },
  addJobOpening: {
    path: '/:service_slug?/job-opening/add',
    element: <AddJobOpeningPage />
  },
  editJobOpening: {
    path: '/:service_slug?/job-opening/edit/:id',
    element: <EditJobOpeningPage />
  },
  shareAccessJobOpening: {
    path: '/:service_slug?/job-opening/share-access/:id',
    element: <ShareAccessJobOpeningPage />
  },
  jobOpeningDetail: {
    path: '/:service_slug?/job-opening/detail/:id',
    element: <JobOpeningDetailPage />
  },
  department: {
    path: '/:service_slug?/department',
    element: <DepartmentListPage />
  },
  addDepartment: {
    path: '/:service_slug?/department/add',
    element: <AddDepartmentPage />
  },
  editDepartment: {
    path: '/:service_slug?/department/edit/:id',
    element: <EditDepartmentPage />
  },
  leaveType: {
    path: '/:service_slug?/leave-type',
    element: <LeaveTypeListPage />
  },
  jobtitle: {
    path: '/:service_slug?/job-title',
    element: <JobTitlePage />
  },
  jobOpeningStatus: {
    path: '/:service_slug?/job-opening-status',
    element: <JobOpeningStatusPage />
  },
  interviewStatus: {
    path: '/:service_slug?/hiring-pipeline',
    element: <InterviewStatusListPage />
  },
  leaveStatus: {
    path: '/:service_slug?/leave-status',
    element: <LeaveStatusListPage />
  },
  leaveTracker: {
    path: '/:service_slug?/leave-tracker',
    element: <LeaveTrackerListPage />
  },
  leaveApplication: {
    path: '/:service_slug?/leave-application',
    element: <LeaveApplicationListPage />
  },
  attendance: {
    path: '/:service_slug?/attendance',
    element: <AttendanceListPage />
  },
  attendanceReport: {
    path: '/:service_slug?/attendance-report',
    element: <AttendanceReportListPage />
  },
  manageHoliday: {
    path: '/:service_slug?/manage-holiday',
    element: <ManageHolidayListPage />
  },
  userHolidayList: {
    path: '/:service_slug?/holiday',
    element: <UserHolidayListPage />
  },
  jobOpeningAttachmentCategory: {
    path: '/:service_slug?/attachment-category',
    element: <JobOpeningAttachmentCategoryListPage />
  },
  sourceSetting: {
    path: '/:service_slug?/source-settings',
    element: <SourceSettingPage />
  },
  visaType: {
    path: '/:service_slug?/visa-type',
    element: <VisaTypePage />
  },
  serviceCategory: {
    path: '/:service_slug?/service-category',
    element: <ServiceCategoryPage />
  },
  companyService: {
    path: '/:service_slug?/service',
    element: <ServicePage />
  },
  reference: {
    path: '/:service_slug?/reference',
    element: <ReferencePage />
  },
  documentType: {
    path: '/:service_slug?/document-type',
    element: <DocumentTypePage />
  },
  payType: {
    path: '/:service_slug?/pay-type',
    element: <PayTypeListPage />
  },
  skill: {
    path: '/:service_slug?/skill',
    element: <SkillListPage />
  },
  shift: {
    path: '/:service_slug?/shift',
    element: <ShiftListPage />
  },
  interViewLevel: {
    path: '/:service_slug?/interview-level',
    element: <InterViewLevelPage />
  },
  employeeType: {
    path: '/:service_slug?/employee-type',
    element: <EmployeeTypePage />
  },
  candidateStatus: {
    path: '/:service_slug?/candidate-stage',
    element: <CandidateStatusPage />
  },
  // account.......
  account: {
    path: '/:service_slug?/account',
    element: <AccountPage />
  },
  addAccount: {
    path: '/:service_slug?/account/add',
    element: <AddAccountPage />
  },
  editAccount: {
    path: '/:service_slug?/account/edit/:id',
    element: <EditAccountPage />
  },
  shareAccessAccount: {
    path: '/:service_slug?/account/share-access/:id',
    element: <ShareAccessAccountPage />
  },
  // lead...............................
  leadStage: {
    path: '/:service_slug?/lead-stage',
    element: <LeadStagePage />
  },
  lead: {
    path: '/:service_slug?/lead',
    element: <LeadPage />
  },
  addLead: {
    path: '/:service_slug?/lead/add',
    element: <AddLeadPage />
  },
  editLead: {
    path: '/:service_slug?/lead/edit/:id',
    element: <EditLeadPage />
  },
  leadAndDevelopmentDetail: {
    path: '/:service_slug?/:type/detail/:id',
    element: <LeadDetailPage />
  },
  // lead development...................
  leadDevelopment: {
    path: '/:service_slug?/development',
    element: <LeadDevelopmentPage />
  },
  // proposal
  proposalStage: {
    path: '/:service_slug?/proposal-stage',
    element: <ProposalStagePage />
  },
  proposal: {
    path: '/:service_slug?/proposal',
    element: <ProposalPage />
  },
  addProposal: {
    path: '/:service_slug?/proposal/add',
    element: <AddProposalPage />
  },
  editProposal: {
    path: '/:service_slug?/proposal/edit/:id',
    element: <EditProposalPage />
  },
  //..........
  companyBranch: {
    path: '/:service_slug?/branch',
    element: <CompanyBranchListPage />
  },
  addCompanyBranch: {
    path: '/:service_slug?/branch/add',
    element: <AddCompanyBranchPage />
  },
  editCompanyBranch: {
    path: '/:service_slug?/branch/edit/:id',
    element: <EditCompanyBranchPage />
  },
  employee: {
    path: '/:service_slug?/employee',
    element: <EmployeeListPage />
  },
  addEmployee: {
    path: '/:service_slug?/employee/add',
    element: <AddEmployeePage />
  },
  editEmployee: {
    path: '/:service_slug?/employee/edit/:id',
    element: <EditEmployeePage />
  },
  client: {
    path: '/:service_slug?/client',
    element: <ClientListPage />
  },
  addClient: {
    path: '/:service_slug?/client/add',
    element: <AddClientPage />
  },
  editClient: {
    path: '/:service_slug?/client/edit/:id',
    element: <EditClientPage />
  },
  submittedCandidate: {
    path: '/:service_slug?/submitted-candidate',
    element: <SubmittedCandidateListPage />
  },
  userChat: {
    path: '/:service_slug?/chat',
    element: <ChatPage />
  },
  userProfile: {
    path: '/profile',
    element: <UserProfilePage />
  },
  userSettings: {
    path: '/settings',
    element: <UserSettingPage />
  },
  userSettingsUI: {
    path: '/settings-ui',
    element: <UserSettingUIPage />
  },
  userSettingsLinkAccount: {
    path: '/settings/:linkAccountType/callback',
    element: <UserSettingLinkAccountTypeCallbackPage />
  },
  emailSettingsLinkAccount: {
    path: '/email-settings/:linkAccountType/callback',
    element: <EmailSettingLinkAccountTypeCallbackPage />
  },
  // uyser email template
  emailTemplate: {
    path: '/personalized-template',
    element: <EmailTemplateListPage />
  },
  addEmailTemplate: {
    path: '/personalized-template/add',
    element: <AddEmailTemplatePage />
  },
  editEmailTemplate: {
    path: '/personalized-template/edit/:id',
    element: <EditEmailTemplatePage />
  },
  // system email template
  systemEmailTemplate: {
    path: '/automated-template',
    element: <SystemTemplateListPage />
  },
  addSystemEmailTemplate: {
    path: '/automated-template/add',
    element: <AddSystemEmailTemplatePage />
  },
  editSystemEmailTemplate: {
    path: '/automated-template/edit/:id',
    element: <EditSystemEmailTemplatePage />
  },
  darkModeDemo: {
    path: 'dark-mode',
    element: <DarkModeDemoPage />
  },
  darkModeCustomize: {
    path: 'dark-mode-customize',
    element: <DarkModeCustomizePage />
  },
  starter: {
    path: 'starter',
    element: <StarterPage />
  },
  wizardExample: {
    path: 'wizard',
    element: <WizardExample />
  },
  datePickerExample: {
    path: 'date-picker',
    element: <DatePickerExample />
  }
};

export const loginRoutes: { [key: string]: Route } = {
  signin: { path: '/login', element: <SignInPage /> },
  twoFA: { path: '/verify', element: <TwoFAPage /> },
  forgotPassword: { path: '/forgot-password', element: <ForgotPasswordPage /> },
  resetPassword: { path: '/reset-password', element: <ResetPasswordPage /> },
  createPassword: {
    path: '/create-account-password',
    element: <CreatePasswordPage />
  }
};

export const userRoutes: { [key: string]: Route } = {
  roleSelection: { path: '/role-selection', element: <RoleselectionPage /> },
  acceptRejectAccess: {
    path: '/invitation',
    element: <AcceptRejectAccessInvitationPage />
  }
};

export const getUserHomePageRoute = (): string => {
  return placeParamsToURL(mainRoutes.dashboard.path);
};
