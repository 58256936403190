import React from 'react';

const EmailTemplateSvg = ({
  height = 18,
  width = 18,
  viewBox = '0 0 512 512',
  className
}: {
  height?: any;
  width?: any;
  viewBox?: any;
  className?: any;
}) => {
  return (
    <>
      <svg
        id="OBJECT"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className={className}
        viewBox={viewBox}
        fill="currentColor"
      >
        <g>
          <path d="m80.141 257.355v-109.171l-69.694 54.584z" />
          <path d="m431.858 257.34 69.677-54.581-69.677-54.572z" />
          <path d="m224.319 35.264h63.361l-31.68-24.812z" />
          <path d="m88.399 43.521v220.301l90.178 70.63 74.667-66.263c1.564-1.388 3.917-1.388 5.48 0l74.694 66.263 90.181-70.644v-220.287zm161.923 159.625h-117.353c-2.28 0-4.129-1.849-4.129-4.129s1.849-4.129 4.129-4.129h117.353c2.28 0 4.129 1.849 4.129 4.129s-1.849 4.129-4.129 4.129zm-121.482-35.359c0-2.28 1.849-4.129 4.129-4.129h45.774c2.28 0 4.129 1.849 4.129 4.129s-1.849 4.129-4.129 4.129h-45.774c-2.281 0-4.129-1.849-4.129-4.129zm250.191-92.144c2.28 0 4.129 1.849 4.129 4.129v46.374c0 2.28-1.849 4.129-4.129 4.129h-246.062c-2.28 0-4.129-1.849-4.129-4.129v-46.374c0-2.28 1.849-4.129 4.129-4.129z" />
          <path d="m507.476 208.596-167.777 131.428 167.937 149.03c2.826-4.341 4.364-9.386 4.364-14.596v-251.025c0-5.276-1.602-10.417-4.524-14.837z" />
          <path d="m172.299 340.024-167.803-131.428c-2.904 4.407-4.496 9.547-4.496 14.837v251.025c0 5.229 1.531 10.278 4.333 14.596z" />
          <path d="m484.912 501.548c6.346 0 12.447-2.261 17.264-6.299l-171.598-152.278c-.084-.072-.177-.13-.257-.209-.021-.021-.034-.046-.055-.067l-74.28-65.896-74.502 66.117-171.688 152.332c4.83 4.045 10.936 6.3 17.293 6.3z" />
        </g>
      </svg>
    </>
  );
};
export default EmailTemplateSvg;
