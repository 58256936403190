import store from 'store';
import api from 'config/api';
import { manageAPICall } from './CommonCrudAction';
import axios from 'axios';
import { GOOGLE_SLUG, ZOOM_SLUG } from 'helpers/DataHelper';
import { decryptText } from 'helpers/CommonHelper';

export const getConfiguredLinkAccountSettingList = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.getConfiguredLinkAccountSettingList.url,
    api.getConfiguredLinkAccountSettingList.method,
    data,
    callback
  );
};
export const configureLinkAccountSetting = async (data: object) => {
  const response = manageAPICall(
    api.configureLinkAccountSetting.url,
    api.configureLinkAccountSetting.method,
    data
  );
  return response;
};
export const editLinkAccountSetting = async (data: object, callback: any) => {
  manageAPICall(
    api.editLinkAccountSetting.url,
    api.editLinkAccountSetting.method,
    data,
    callback
  );
};

export const deleteLinkAccountSetting = async (data: object, callback: any) => {
  manageAPICall(
    api.deleteLinkAccountSetting.url,
    api.deleteLinkAccountSetting.method,
    data,
    callback
  );
};
// email account link
export const getConfiguredEmailAccountList = async (
  data: object,
  callback: any
) => {
  manageAPICall(
    api.getConfiguredEmailAccountList.url,
    api.getConfiguredEmailAccountList.method,
    data,
    callback
  );
};
export const configureEmailAccount = async (data: object, callback: any) => {
  manageAPICall(
    api.configureEmailAccount.url,
    api.configureEmailAccount.method,
    data,
    callback
  );
};
export const editCustomEmailAccount = async (data: object, callback: any) => {
  manageAPICall(
    api.editCustomEmailAccount.url,
    api.editCustomEmailAccount.method,
    data,
    callback
  );
};
export const deleteEmailAccount = async (data: object, callback: any) => {
  manageAPICall(
    api.deleteEmailAccount.url,
    api.deleteEmailAccount.method,
    data,
    callback
  );
};
export const getLinkAccountAuthorizationURL = (
  credentialObj: any,
  rowDetails: any,
  scopeList: any,
  redirectBackURL: any
) => {
  let authorizationUrl = '';
  if (rowDetails.type == ZOOM_SLUG) {
    authorizationUrl = `https://zoom.us/oauth/authorize?response_type=${'code'}&client_id=${decryptText(credentialObj.clientId)}&redirect_uri=${redirectBackURL}`;
  } else if (rowDetails.type == GOOGLE_SLUG) {
    authorizationUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${decryptText(credentialObj.clientId)}&redirect_uri=${redirectBackURL}&scope=${scopeList.join(
      ' '
    )}&access_type=offline&prompt=consent`;
  }
  return authorizationUrl;
};
